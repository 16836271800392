<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 14V16H2V14H13ZM18 7V9H0V7H18ZM16 0V2H5V0H16Z"
      :fill="props.color"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  color: {
    type: String,
    default: "white",
  },
});
</script>
